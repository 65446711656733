<template>
  <div class="p-3">
    <rightToWorkInAustraliaCertificate
      :propsData1="getUserFromId"
      :propsData2="getRightWorkAustralia"
    ></rightToWorkInAustraliaCertificate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import rightToWorkInAustraliaCertificate from "../../components/checkRightToWorkInAustralia/RightToWorkInAustraliaCertificate.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "rightToWorkInAustraliaCertificateCom",

  components: {
    rightToWorkInAustraliaCertificate,
  },

  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
  computed: {
    ...mapGetters(["getRightWorkAustralia", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchRightWorkAustralia", "fetchUserFromId"]),
  },
  created() {
    this.fetchUserFromId(this.userId);
    this.fetchRightWorkAustralia(this.projectId);
  },
};
</script>